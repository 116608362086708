var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"100%"}},[(_vm.myRecipes && _vm.myRecipes.length === 0)?_c('div',{staticClass:"text-center"},[_vm._v(" You do not have recipes yet ")]):(_vm.loading)?_c('v-progress-circular',{staticStyle:{"width":"100%"},attrs:{"indeterminate":"","color":"green"}}):_c('v-card',[_c('v-list',[_c('v-list-item-group',_vm._l((_vm.myRecipes),function(recipe){return _c('v-list-item',{key:recipe.id,nativeOn:{"click":function($event){$event.stopPropagation();return (function () {
              _vm.$router.push({
                name: 'Recipe calculator',
                params: {
                  ingredients: recipe.recipe
                }
              })
            })($event)}}},[_c('RecipeRow',{attrs:{"recipe":recipe}}),_c('v-list-item-icon',[_c('Dialog',[_c('template',{slot:"activator"},[_c('v-btn',{attrs:{"text":"","icon":""}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1),_c('template',{slot:"title"},[_vm._v(" Delete ")]),[_vm._v(" ¿Do you want to delete the recipe "+_vm._s(recipe.name)+"? ")],_c('template',{slot:"actions"},[_c('v-btn',{attrs:{"text":""}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"red","dark":""},on:{"click":function($event){return _vm.doDeleteRecipe(recipe.id)}}},[_vm._v("Delete")])],1)],2)],1)],1)}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }